import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Reroute from './Reroute';
import Privacy from './Privacy';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/privacy-policy.html' element={<Privacy />} />
          <Route path='*' element={<Reroute />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
