import React, { useEffect } from "react";
import happy from './assets/happy.png';

const Reroute = ()=>{
    useEffect(()=>{
        var v;
        try {
            v=window.open(`thap://thap.in${window.location.pathname}${window.location.search}`, '_parent');
        } catch (error) {
            v.close();
            window.focus();
            window.location.replace(`thap://thap.in${window.location.pathname}${window.location.search}`);
        }
    }, [])

    return(
        <div className="container" style={{ textAlign: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center' }} >
            <img src={happy} style={{ height: '20vw', objectFit: 'contain', maxHeight: '50vh' }} alt='happiness' />
        <p><a href={`thap://thap.in${window.location.pathname}${window.location.search}`} >
            Click here
        </a> if you are not automatically redirected to the App</p>
        </div>
    )
}


export default Reroute;