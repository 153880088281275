import React from 'react';
import logo from './assets/logo.png';
import circles from './assets/circles.png';
import menu from './assets/menu.png';
import happy from './assets/happy.png';

const Home = () => {
    return <>
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h3>An adventure to wholesome life awaits..</h3>
            <img src={menu} className="App-menu" />
        </header>

        <div style={{ width: '60%', maxHeight: '60%', margin: 'auto', backgroundColor: '#fff', boxShadow: '5px 5px 10px #000', alignSelf: 'center', flexDirection: 'column', display: 'flex', justifyContent: 'space-evenly', padding: '1%', borderRadius: 5 }} >
            <img src={happy} style={{ maxHeight: '20%', maxWidth: '50%', alignSelf: 'center', objectFit: 'contain' }} />
            <button onClick={(e) => {
                window.open("https://coach.thap.in")
            }} style={{ border: 'none', padding: '2%', borderRadius: 5, margin: '2%', backgroundColor: '#6CC3B8', boxShadow: '5px 5px 5px #333', cursor: 'pointer' }} >
                Coach App
            </button>
            <a href='thap://thap.in' style={{ border: 'none', padding: '2%', borderRadius: 5, margin: '2%', backgroundColor: '#6CC3B8', boxShadow: '5px 5px 5px #333', cursor: 'pointer', textDecoration: 'none', color: '#000' }}  >
                Mobile App
            </a>
        </div>
    </>
}


export default Home;